import validator from "@rjsf/validator-ajv8";
import { Form } from "@rjsf/mui";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import DeleteButton from "../../utils/DeleteButton";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { useTranslation } from "react-i18next";
import UserWidget from "../users/UserWidget";
const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const MeterAssoc = () => {
  const [formData, setFormData] = useState({});
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();
  const schema = {
    title: "New user",
    type: "object",

    properties: {
      user_id: {
        $id: "2",
        type: "string",
        title: t("user"),
        /*   default: paramsList.zip_code, */
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("associate_user"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    user_id: {
      "ui:field": "user",
    },
  };
  const fields = {
    user: UserWidget,
  };

  return (
    <>
      <DeleteConfirmModal
        trashModal={modal}
        setTrashModal={setModal}
        text={t("disasoc_user_text")}
        title={t("disasoc_user_title")}
        /* deleteFunc={() => setMeterTrashId(id)} */
      />
      <Form
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        formData={formData}
        showErrorList={false}
        onChange={({ formData: newFormData }) => setFormData(newFormData)}
        /*   onSubmit={(values) => {
      onSubmitClick(values.formData);
    }} */
        validator={validator}
        children={true}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mt: 2,
        }}
      >
        <Button variant={window?.BUTTON_TYPE}>{t("assoc_user")}</Button>
        <DeleteButton name={t("disasoc_user")} onClick={() => setModal(true)} />
      </Box>
    </>
  );
};
export default MeterAssoc;
